import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: 'input[numbersOnly]'
})
export class NumberDirective {

  constructor(private _el: ElementRef) { }
  @Input() numbersOnly: any;

  @HostListener('input', ['$event']) onInputChange(event) {
    const initalValue = this._el.nativeElement.value.toUpperCase();
    if (this.numbersOnly == "false") {
      this._el.nativeElement.value = initalValue.replace(/[^0-9]*/g, '');
      if (initalValue !== this._el.nativeElement.value) {
        event.stopPropagation();
      }
    }
    else if (this.numbersOnly == "true") {
      this._el.nativeElement.value = initalValue.replace(/[^A-Za-z0-9]*/g, '');
      if (initalValue !== this._el.nativeElement.value) {
        event.stopPropagation();
      }
    }
  }

}
